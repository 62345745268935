import Vue from 'vue'
import VueYandexMetrika from 'vue-yandex-metrika'
import router from '@/router'

if (process.env.VUE_APP_YANDEX_METRIKA_ID) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_METRIKA_ID,
    router: router,
    env: process.env.NODE_ENV,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    }
  })
}

Vue.prototype.$reachGoal = (event) => {
  if (Vue.prototype.$metrika) {
    Vue.prototype.$metrika.reachGoal(event)
  }
}
