const blogRoutes = [
  {
    path: '/',
    component: () => import('layouts/Main'),
    children: [
      {
        path: 'blog',
        name: 'Blog',
        component: () => import('views/Blog')
      },
      {
        path: 'blog/raschet-teplopoter',
        name: 'RaschetTeplopoter',
        component: () => import('views/articles/RaschetTeplopoter')
      },
      {
        path: 'blog/raschet-teplogo-pola',
        name: 'RaschetTeplogoPola',
        component: () => import('views/articles/RaschetTeplogoPola')
      },
      {
        path: 'blog/raschet-radiatorov',
        name: 'RaschetRadiatorov',
        component: () => import('views/articles/RaschetRadiatorov')
      },
      {
        path: 'blog/zachem-nuzhny-raschety',
        name: 'ZachemNuzhnyRaschety',
        component: () => import('views/articles/ZachemNuzhnyRaschety')
      }
    ]
  }
]

export { blogRoutes }
