import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { api } from './api'

import VModal from 'vue-js-modal'
import VueYouTubeEmbed from 'vue-youtube-embed'
import Toasted from 'vue-toasted'
import VueMeta from 'vue-meta'

import '@/vendors'

Vue.config.productionTip = false

// API
Vue.prototype.$api = api

// Components
Vue.use(VModal)

Vue.use(VueYouTubeEmbed)
Vue.use(Toasted)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  render: h => h(App),
  mounted () {
    // Start prerender-spa-plugin
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
