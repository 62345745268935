import Vue from 'vue'
import VueRouter from 'vue-router'

import { authRoutes } from './modules/auth'
import { blogRoutes } from './modules/blog'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('layouts/Main'),
    children: [
      {
        path: '',
        name: 'Root',
        component: () => import('views/Home')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('views/About')
      }
    ]
  },
  {
    path: '/pricing',
    name: 'PaymentPlans',
    component: () => import('views/PaymentPlans')
  }
].concat(authRoutes, blogRoutes)
  .concat(
    [
      { path: '*', component: () => import('views/PageNotFound') }
    ])

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      const element = document.getElementById(to.hash.replace(/#/, ''))
      if (element && element.scrollIntoView) {
        element.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
