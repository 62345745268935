import axios from 'axios'

const adapter = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URI,
  headers: {
    Accept: 'application/json'
  }
})

// adapter.interceptors.response.use((response) => {
//   return response
// }, (error) => {
//   if (error.response) {
//     if (error.response.status === 401 && !error.response.config.url.includes('/auth/sign_in')) {
//       window.location.href = '/sign_in'
//     }
//   }
//   return Promise.reject(error)
// })

const api = {
  CancelToken: axios.CancelToken,
  isCancel: axios.isCancel,
  signIn: (params) => adapter.post('/v1/auth/sign_in', params),
  signInOmniAuth: (method) => {
    window.location = `${process.env.VUE_APP_BASE_API_URI}/v1/auth/${method}?auth_origin_url=${location.origin}/sign_in_oauth&resource_class=User`
  },
  signUp: (params) => adapter.post('/v1/auth', params),
  signOut: '/v1/auth/sign_out',
  getProfile: () => adapter.get('v1/auth/validate_token'),
  resetPassword: (params) => adapter.post('/v1/auth/password', params),
  auth: {
    updatePassword: (params, config) => adapter.put('/v1/auth/password', params, config)
  },
  users: {
    verify_email: (email) => adapter.post('/v1/users/verify_email', { email })
  }
}

export { api }
