const authRoutes = [
  {
    path: '/',
    component: () => import('layouts/Auth'),
    children: [
      {
        path: 'sign_in',
        name: 'SignIn',
        component: () => import('views/auth/SignIn')
      },
      {
        path: 'sign_up',
        name: 'SignUp',
        component: () => import('views/auth/SignUp')
      },
      {
        path: 'sign_in_oauth',
        name: 'SignInOauth',
        component: () => import('views/auth/SignInOauth')
      },
      {
        path: 'request_password_reset',
        name: 'PasswordRecoveryRequest',
        component: () => import('views/auth/PasswordRecoveryRequest')
      },
      {
        path: 'reset_password',
        name: 'PasswordReset',
        component: () => import('views/auth/PasswordReset')
      }
    ]
  }
]

export { authRoutes }
