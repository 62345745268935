<template lang="pug">
  #app.wrapper
    router-view
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Онлайн калькулятор - сервис для расчета систем отопления',
    meta: [
      {
        name: 'yandex-verification',
        content: '68e9e3aa2e5961a7'
      },
      {
        name: 'google-site-verification',
        content: 'l5DEpra1EqajXOcbGfdo0diSkDHPQv96upUSH9ukhVA'
      },
      {
        name: 'wmail-verification',
        content: '35a59c525002fabb98fe627f0c3ac04a'
      },
      {
        name: 'cmsmagazine',
        content: '63667ecfa2dad2852e11951e87cde2b4'
      },
      {
        property: 'og:image',
        content: 'https://heatingcalculator.online/assets/images/redesign/logo.png'
      }
    ]
  }
}
</script>

<style lang="sass">
  @import 'stylesheets/style'
</style>
